<template>
  <div class="tooltip">
    <Teleport to="body">
      <Transition
        name="tt-fade"
        @before-enter="setDuration"
        @after-enter="cleanUpDuration"
        @before-leave="setDuration"
        @after-leave="cleanUpDuration"
      >
        <div v-show="globalTooltip.isActive" ref="ttBody" class="tooltip-content" @mouseenter="contentEnter" @mouseleave="contentLeave">
          <component :is="globalTooltip.ttContent" v-bind="globalTooltip.ttContentProps" />
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
const globalTooltip = useTooltip();

const ttBody = ref<HTMLDivElement | null>(null);
watch(ttBody, () => {
  if (ttBody.value) {
    globalTooltip.registerBody(ttBody.value);
  }
});

const contentEnter = (e: MouseEvent) => {
  globalTooltip.contentEnter(e);
};

const contentLeave = () => {
  globalTooltip.contentLeave();
};

const setDuration = (el: Element) => {
  if (el instanceof HTMLElement) {
    el.style.transitionDuration = "200ms";
  }
};

const cleanUpDuration = (el: Element) => {
  if (el instanceof HTMLElement) {
    el.style.transitionDuration = "";
  }
};
</script>
